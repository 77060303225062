var EfbHelperForm = (function () {

    var _private = {};

    _private.instance = this;

    this.initDatePickerWithTime = function (element, selector, changeDay) {
        if (typeof changeDay === 'undefined') {
            changeDay = false;
        }
        if (element === null) {
            element = $(selector);
        } else if (typeof selector === 'string' && selector !== '') {
            element = $(element).find(selector);
        }
        var optionsGreaterThan = {};
        $(element).each(function (index, element) {
            var fromTimeElement   = null;
            var datePickerElement = $(element).find('input.date-picker');
            $(datePickerElement).removeAttr('readonly');
            var className        = $(element).attr('class');
            var greaterThanIndex = -1;
            if (!_.isUndefined(className)) {
                greaterThanIndex = className.indexOf('gt-');
            }
            if (greaterThanIndex !== -1) {
                className = className.substr(greaterThanIndex).split(' ');
                var parts = className[0].split('-');
                $(datePickerElement).closest('form').find('input[name="' + parts[1] + '-date"]');
                fromTimeElement = $(datePickerElement).closest('form').find('input[name="' + parts[1] + '-time"]');

            }
            var options = {
                timeFormat: "HH:MM",
                dateFormat: "yy-mm-dd",
                onSelect:   function () {
                    var update = _private.instance.updateDatePickerWithTimeHidden(element);
                    if (update && fromTimeElement !== null) {
                        Validator.validateTimeRange($(fromTimeElement).parent('div'), $(element));
                    }
                    $(".ui-datepicker a").removeAttr("href");
                }
            };
            $.extend(options, optionsGreaterThan);
            try {
                $(datePickerElement).datepicker('destroy');
            } catch (e) {
                console.log('Datepicker could not destroy');
                console.log($(datePickerElement));
            }
            $(datePickerElement).datepicker(options);
            $(datePickerElement).unbind('keyup.initDatePickerWithTime').on('keyup.initDatePickerWithTime',
                function (event) {
                    var element = $(event.currentTarget).parent('div');
                    var valid   = Validator.validateDate(event.currentTarget);
                    if (valid) {
                        _private.instance.updateDatePickerWithTimeHidden(element);
                        if (fromTimeElement !== null) {
                            Validator.validateTimeRange($(fromTimeElement).parent('div'), $(element));
                        }
                    }
                }
            );

            var timeElement = $(element).find('input.time-input');

            var ignoreKey = false;
            var handler   = function (e) {
                if (ignoreKey) {
                    e.preventDefault();
                    return;
                }
                if (e.keyCode === 38 || e.keyCode === 40) {
                    var pos             = this.selectionStart;
                    this.selectionStart = pos;
                    this.selectionEnd   = pos;
                    ignoreKey           = true;
                    setTimeout(function () {
                        ignoreKey = false;
                    }, 1);
                    e.preventDefault();
                }
            };
            $(timeElement).each(function (i, input) {
                if (!_.isUndefined(input.addEventListener) && _.isFunction(input.addEventListener)) {
                    input.addEventListener('keydown', handler, false);
                }
            });

            function updateTime(event) {
                if (!(event.keyCode === 38 || event.keyCode === 40) || !Validator.validateTime(timeElement)) {
                    return;
                }
                var textParts = $(event.currentTarget).val().split(':');
                if (textParts.length !== 2) {
                    return;
                }
                var check = textParts[0].substr(0, 1);
                if (check === '0') {
                    textParts[0] = textParts[0].substr(1, 1);
                }
                check = textParts[1].substr(0, 1);
                if (check === '0') {
                    textParts[1] = textParts[1].substr(1, 1);
                }
                var oldHours;
                var hours          = oldHours = parseInt(textParts[0]);
                var minutes        = parseInt(textParts[1]);
                var cursorPosition = $(event.currentTarget).caret();
                var maxHours       = 23;
                var maxMinutes     = 59;
                if (cursorPosition > 2) {
                    if (event.keyCode === 38) {
                        minutes++;
                    } else {
                        minutes--;
                    }
                } else {
                    if (event.keyCode === 38) {
                        hours++;
                    } else {
                        hours--;
                    }
                }
                if (minutes > maxMinutes) {
                    minutes = 0;
                    hours++;
                } else if (minutes < 0) {
                    minutes = maxMinutes;
                    hours--;
                }
                if (hours > maxHours) {
                    hours = 0;
                } else if (hours < 0) {
                    hours = maxHours;
                }
                if (hours < 10) {
                    hours = '0' + hours;
                }
                if (minutes < 10) {
                    minutes = '0' + minutes;
                }
                if (changeDay) {
                    var dateElement   = $(event.currentTarget).parent('div').find('input.date-picker');
                    var hiddenElement = $(event.currentTarget).parent('div').find('input[type=hidden]');
                    var date          = EfbHelper.strToDate($(dateElement).val());
                    if (event.keyCode === 38 && parseInt(hours, 10) === 0 && parseInt(oldHours, 10) === 23) {
                        date.setDate(date.getDate() + 1);
                    } else if (event.keyCode === 40 && parseInt(hours, 10) === 23 && parseInt(oldHours, 10) === 0) {
                        date.setDate(date.getDate() - 1);
                    }
                    $(dateElement).val($.datepicker.formatDate('yy-mm-dd', date));
                    $(hiddenElement).val($.datepicker.formatDate('yy-mm-dd', date) + ' ' + hours + ':' + minutes + ':00');
                }
                $(event.currentTarget).val(hours + ':' + minutes);
                $(event.currentTarget).caret(cursorPosition);
                var update = _private.instance.updateDatePickerWithTimeHidden($(timeElement).parent('div'));
                if (Validator.validateTime(timeElement) && update) {
                    if (fromTimeElement !== null) {
                        Validator.validateTimeRange($(fromTimeElement).parent('div'), $(element));
                    }
                }
            }
            $(timeElement).unbind('keydown.initDatePickerWithTime').on('keydown.initDatePickerWithTime',
                function (event) {
                    updateTime(event);
                }
            );
            $(timeElement).unbind('keyup.initDatePickerWithTime').on('keyup.initDatePickerWithTime',
                function (event) {
                    var formElement = $(event.target).parents('div.formelement');
                    if ($(formElement).hasClass('isRequired')) {
                        Validator.validateIsRequired(event.target);
                    }
                    EfbHelper.autocompleteTimeStampField(event);
                    if (Validator.validateTime(event.target)) {
                        _private.instance.updateDatePickerWithTimeHidden($(event.target).parent('div'), event);
                    }
                }
            );
        });

    };

    this.initFloatValElements = function (form) {
        _private.instance.initFloatValElement($(form).find('input.round-value-element'));
    };

    this.initFloatValElement = function (element) {
        element.unbind('keyup.setFloatVal').on('keyup.setFloatVal',
            function (event) {
                if ($.inArray(event.keyCode, [undefined, 37, 38, 39, 40, 9]) !== -1) {
                    return;
                }
                var value = $(event.target).val();
                if (value.indexOf(',') !== -1) {
                    var start = event.target.selectionStart,
                        end   = event.target.selectionEnd;
                    $(event.target).val(value.replace(',', '.'));
                    event.target.setSelectionRange(start, end);
                }
                $(event.target).attr('pure-value', $(event.target).val());
            }
        );
    };

    this.updateDatePickerWithTimeHidden = function (element) {
        var hiddenElement = $(element).find('input[type=hidden]');
        var date          = $.trim($(element).find('input.date-picker').val());
        var time          = $.trim($(element).find('input.time-input').val());
        if (date === '' || time === '') {
            return false;
        }
        $(hiddenElement).val(date + ' ' + time + ':00').keyup();
        return true;
    };


    this.initDynamicTextArea = function (textarea) {
        textarea = $(textarea);
        if (!textarea.is('textarea')) {
            return false;
        }
        autosize(textarea);
        setTimeout(function () {
            autosize.update(textarea);
        }, 100);
        return true;
    };


    this.initPseudoButtons = function () {
        $('a.submit').unbind('click.pseudoSubmit').on('click.pseudoSubmit', function (event) {
            $(event.target).parents('form').submit();
        });
    };

    this.initDatePicker = function (element, opt) {
        if (!element.hasClass('date-picker')) {
            element = element.find('div.formelement.date-picker');
        }
        if (!element.length) {
            return;
        }
        $(element).each(function (i, element) {
            var input    = $(element).find('input[type=text]');
            var altField = $(element).find('input[type=hidden]');
            var options  = $(element).find('input[type=hidden]').data();
            if (input.hasClass('hasDatepicker')) {
                return;
            }

            //input.addClass('hasDatepicker');
            try {
                element.datepicker('destroy');
            } catch (e) {
            }
            options.altField = altField;
            options.appendTo = input.parent();
            if (opt) {
                options = $.extend(options, opt);
            }
            if (_.isUndefined(options.onSelect)) {
                options.onSelect = function (date, ui) {
                    $(ui.input).keyup();
                    $(ui.input).blur();
                    $(ui.settings.altField).change();
                    $(ui.input).trigger('selectDate', [$(ui.input), $(ui.input).datepicker('getDate')]);
                    $(ui.input).parents('form').trigger('selectDate', [$(ui.input), $(ui.input).datepicker('getDate')]);
                }
            }
            input.datepicker(options);
        });
    };

    /**
     *
     * @param element {jQuery}
     * @returns {*}
     */
    this.formatElementValue = function (element) {
        var value = element.val();
        value     = parseFloat(value);
        if (_.isNaN(value)) {
            return element;
        }
        if (!_.isUndefined(element.data('format'))) {
            value = $.sprintf(element.data('format'), value);
        } else if (!_.isUndefined(element.attr('precision'))) {
            value = parseFloat(value.toFixed(parseInt(element.attr('precision')))).toString();
        } else {
            return element;
        }
        return element.val(value);
    };

    this.initAutocompleteObject = function (element, selectCB) {

        console.warn('EfbHelper.initAutocompleteObject is deprecated use EfbHelperAutocomplete');

        selectCB             = selectCB || function () {};
        var getRequestParams = function (input, searchTerm) {
            var params = {filter: {}, order: {}};
            var data   = input.data();

            data.fieldFormat = data.fieldFormat || '#term#%';
            data.objectField = data.objectField || 'unknown';
            data.objectLimit = data.objectLimit || 10;

            params.order[data.objectField]  = 'ASC';
            params.filter[data.objectField] = {like: data.fieldFormat.replace('#term#', searchTerm)};
            params.limit                    = data.objectLimit;

            return params;
        };
        element.each(function (i, inputAC) {
            inputAC         = $(inputAC);
            var inputV      = inputAC.next('input[type=hidden]');
            var objectType  = inputAC.data('objectType');
            var objectField = inputAC.data('objectField');
            var request;
            inputAC.efbAutocomplete({
                minLength: 2,
                delay:     500,
                source:    function (r, response) {
                    request = $.ajax({
                        url:      urlHelper.getSimpleUrl(objectType, 'search'),
                        dataType: 'json',
                        data:     getRequestParams(inputAC, r.term),
                        success:  function (data) {
                            response($.map(data, function (object) {
                                object.label = object[objectField];
                                return object;
                            }));
                        },
                        complete: function () {
                            inputAC.removeClass('ui-autocomplete-loading');
                        },
                        error:    function () {
                            inputAC.removeClass('ui-autocomplete-loading');
                        }
                    });
                },
                select:    function (event, ui) {
                    inputAC.removeClass('searching');
                    inputV.val(ui.item.object_id).change();
                    selectCB.apply(this, [ui.item.object_id, ui.item, inputV]);
                },
                search:    function () {
                    inputV.val('').change();
                    inputAC.removeClass('ui-autocomplete-loading');
                    inputAC.addClass('searching');
                    selectCB.apply(this, [undefined, undefined, inputV]);
                    if (!_.isUndefined(request)) {
                        request.abort();
                        request = undefined;
                    }
                }
            });
        })
    };


    /**
     *
     * @param {*} selects
     * @param {object} [options]
     * @param {function} [dataPrepare]
     */
    this.initChosenElements = function (selects, options, dataPrepare) {
        options = _.isObject(options) ? options : {width: '100%', hide_results_on_select: false};
        if (!_.isFunction(dataPrepare)) {
            dataPrepare = function (data, field, select) {
                {
                    var filterField = select.data('filter_field');
                    var prepared    = [];
                    $.each(data, function (i, d) {
                        prepared.push({value: d.object_id, text: d[filterField]});
                    });
                    return prepared;
                }
            }
        }
        try {
            selects.chosen('destroy');
            selects.parent('div.formelement').find('.chosen-container').remove();
        } catch (e) {
            console.log(e);
        }
        selects.each(function (i, select) {
            select = $(select);
            let chosen;
            select.on('chosen:ready.init', function (e, params) {
                chosen = params.chosen;
            }).on('change.updateChosen', function () {
                if(chosen.is_multiple){
                    chosen.results_build();
                    chosen.results_show();
                }
            }).ajaxChosen({
                dataType:      'json',
                width:         '100%',
                url:           urlHelper.getSimpleUrl('media', 'search'),
                minTermLength: 2,
                dataCallback:  function (d, select) {
                    var filterField          = select.data('filter_field');
                    var data                 = {
                        limit:       10,
                        light:       1,
                        object_type: select.data('object_type'),
                        filter:      {},
                        order:       {}
                    };
                    data.filter[filterField] = {like: '%' + d.term + '%'};
                    data.order[filterField]  = 'ASC';
                    if (_.isFunction(options.filterPrepare)) {
                        options.filterPrepare.apply(this, [data])
                    }
                    return data;
                }
            }, dataPrepare, options);
            if (options.width) {
                select.next('div.chosen-container').css('width', options.width);
            }
        });
    };

});
